import React, { useEffect, useState, useRef } from "react";
import { FaKey } from "react-icons/fa6";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from "./ContractorFilter/ContractorFilter";
import { useDispatch, useSelector } from "react-redux";
import { postMethodData, getMethodData } from "../../../ApiMethods/Api";
import {
  setContractor,
  setStatesList,
  setServiceList,
  setStatusList,
  setVehicleSizeList,
  setLanguage,
  setSelectedRow,
} from "../../../Redux-Toolkit/ContractorSlice";
import { MdRemoveRedEye } from "react-icons/md";
import { updateAssignmentFilters } from "../../../Redux-Toolkit/AssignmentSlice";
import encryptParamID from "../../../utils/encryptParamID";
import assignments from "../../../assets/JNJ-icons/assignment.svg";
import invoice from "../../../assets/JNJ-icons/invoice.svg";
import zip from "../../../assets/JNJ-icons/zip.svg";
import rate_table from "../../../assets/JNJ-icons/rate_table.svg";
import language from "../../../assets/JNJ-icons/language.svg";
import vehicle from "../../../assets/JNJ-icons/veichle.svg";
import driver from "../../../assets/JNJ-icons/driver.svg";
import hours from "../../../assets/JNJ-icons/hours.svg";
import ReactPagination from "../../../utils/ReactPagination";
import { updatemetricsFilters } from "../../../Redux-Toolkit/MatricsSlice";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";
import ContractorClaimantStaffPasswordReset from "../../../Common/ContractorClaimantStaffPasswordReset";

const ContractorManagement = () => {
 const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef();

  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");

  const [contractorDataLoading, setContractorDataLoading] = useState(false);
  const userRole = useSelector((state) => state?.auth?.role);

  const contractorSearchListData = useSelector((state) => state?.contractor?.contractors) || [];
  const contractorFilters = useSelector((state) => state?.contractor?.contractorFilters);
  const selectedRow = useSelector((state) => state?.contractor?.selectedTableRow);

  const rowRefs = useRef([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);
  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [contractorSearchListData]);

  // Helper function to handle the Dispatch
  const fetchAndDispatch = (endpoint, action) => {
    console.log("endpoint", endpoint);
    
    getFilterListDataMethod(endpoint, (response) => {      
      const data = response?.data?.data || [];
      dispatch(action(data));
    });
  };

  useEffect(() => {
    // Fetch filter lists
    fetchAndDispatch("/State", setStatesList);
    fetchAndDispatch("/ContractorService", setServiceList);
    fetchAndDispatch("/ContractorStatus", setStatusList);
    fetchAndDispatch("/VehicleList", setVehicleSizeList);
    fetchAndDispatch("/LanguageList", setLanguage);

    // Fetch contractor search list
    searchList(
      "/ContractorSearch",
      {
        ...contractorFilters,
        page: Number(currentPage),
        limit: ITEMS_PER_PAGE,
      },
      (response) => {        

        if (!response?.data?.status) {
          dispatch(setContractor([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setContractor(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  }, [contractorFilters, currentPage]);

  /**
   *
   * @param {url} url
   * @returns customer states list for usa
   *
   *
   */

  const getFilterListDataMethod = async (url, callback) => {
    try {
     const response = await getMethodData(url);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  /**
   *
   * @param {url} url
   * @returns customers search list
   */

  const searchList = async (url, data, callback) => {
    const body = data;
     try {
      setContractorDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setContractorDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setContractorDataLoading(false);
      dispatch(setContractor([]));
    }
  };

  const encryptId = (id) => encryptParamID(id);
  const encryptencryptId = (ratecodeid) => encryptParamID(ratecodeid);

  /**
   * Pagination options to be used into ReactPagination component
   */
  const pageChangeOptions = {
    endPointFunction: searchList,
    endpoint: "/ContractorSearch",
    componentFilter: contractorFilters,
    dispatchAction: setContractor,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  const [isPasswordResetVisible, setIsPasswordResetVisible] = useState({
    id: null,
    isVisible: false,
  });
  const renderContractorName = (data) => {
    if (data?.firstName || data?.lastName) {
      return `${data?.firstName || "N/A"} ${data?.lastName || ""}`;
    }
    return data?.company || "N/A";
  };  

  return (

    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Contractor Management</p>
        </div>
      </div>
      <Filter />
      <div className="relative">
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Contractor ID</th>
                  <th style={{ whiteSpace: "normal" }}>Contractor Name</th>
                  <th style={{ whiteSpace: "normal" }}>Contractor Company</th>
                  <th style={{ whiteSpace: "normal" }}>Service</th>
                  <th style={{ whiteSpace: "normal" }}>State</th>
                  <th style={{ whiteSpace: "normal" }}>Status</th>
                  <th style={{ whiteSpace: "normal" }}>Action</th>
                </tr>
              </thead>
              <tbody className="text-sm relative">
                {contractorDataLoading &&
                  Array.from({ length: 5 })?.map((_) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {!contractorDataLoading && Array.isArray(contractorSearchListData) && 
                  contractorSearchListData?.length !== 0 &&
                  contractorSearchListData?.map((data, index) => (
                    <tr
                      key={nanoid()}
                      id={data?.contractorID}
                      className={`table-row-align-top ${data?.contractorID === selectedRow ? "activeTableRow" : ""}`}
                      ref={(ele) => (rowRefs.current[index] = ele)}
                      onClick={() => dispatch(setSelectedRow(data.contractorID))}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                      <td>{data?.contractorID}</td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <span className="tooltip text-left" data-tip="View Details">
                              <div
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                              >
                                <button
                                onClick={() => {
                                  if (data?.firstName !== undefined && !data?.isDelete) {
                                    navigate(`/contractor-management/contractor-details/${encryptId(data?.contractorID)}`);
                                  }
                                }}
                              >
                                {renderContractorName(data)}
                                </button>
                              </div>
                            </span>
                            <div className="text-sm">{data?.email || "N/A"}</div>
                            <div className="text-sm">
                              {(
                                data?.cellPhone ||
                                data?.homePhone ||
                                data?.workPhone ||
                                data?.workPhoneExt
                              )
                                ?.replace(/\D/g, "")
                                ?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3") ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{data?.company ?? "N/A"}</td>
                      <td>{data?.service ?? "N/A"}</td>
                      <td>{data?.stateName ?? "N/A"}</td>
                      <td>
                        <label
                          className={`btn capitalize ${data?.inactiveflag === -1
                            ? "bg-[red] hover:bg-[red] "
                            : "bg-[#39ff14] hover:bg-[#39ff14]"
                            }  text-[#000] border-hidden  btn-xs cursor-default`}
                        >
                          {data?.inactiveflag ? "Inactive" : "Active"}
                        </label>
                      </td>

                      
                      <td className=" text-base">
                        <div className="flex items-center space-x-4 flex-wrap">
                          <button title="">
                            <span className="flex flex-wrap">
                              <div className="w-full flex items-center space-x-4 pb-2 mb-2 border-b border-[lightgray]">
                                <span
                                  className="tooltip"
                                  data-tip="View Details"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-management/contractor-details/${encryptId(
                                      data?.contractorID
                                    )}`}
                                  >
                                    <MdRemoveRedEye className="cursor-pointer" />
                                  </Link>
                                </span>

                                {["Administrators", "Schedulers"].includes(
                                  userRole
                                ) && (
                                  <span
                                    className="tooltip"
                                    data-tip="View Assignments"
                                  >
                                    <Link
                                      className="cursor-pointer"
                                      to={`/assignments-management/contractor/${encryptId(
                                        data?.contractorID
                                      )}`}
                                      onClick={() =>
                                        dispatch(
                                          updateAssignmentFilters({
                                            contractorID: data?.contractorID,
                                          })
                                        )
                                      }
                                    >
                                      <img
                                        className="cursor-pointer max-w-none"
                                        alt="Claims"
                                        src={assignments}
                                      />
                                    </Link>
                                  </span>
                                )}

                                {["Administrators", "Billing"].includes(
                                  userRole
                                ) && (
                                  <span
                                    className="tooltip text-left"
                                    data-tip="View Metrics"
                                  >
                                    <Link
                                      className="cursor-pointer"
                                      to={`/metrics-management/contractor/${encryptId(data?.contractorID)}`}
                                      onClick={() =>
                                        dispatch(
                                          updatemetricsFilters({
                                            contractorID: data?.contractorID,
                                          })
                                        )
                                      }
                                    >
                                      <img
                                        className="cursor-pointer max-w-none"
                                        alt="Claims"
                                        src={invoice}
                                      />
                                    </Link>
                                  </span>
                                )}

                                {["Administrators", "Schedulers"].includes(
                                  userRole
                                ) && (
                                  <>
                                    <span
                                      className="tooltip"
                                      data-tip="Update Password"
                                    >
                                      <button
                                      onClick={() => {
                                        setIsPasswordResetVisible({
                                          id: data?.contractorID,
                                          isVisible: true,
                                        });
                                      }}
                                      htmlFor="my-modal-3"
                                    >
                                      <FaKey className="cursor-pointer" />
                                      </button>
                                    </span>

                                    {isPasswordResetVisible.isVisible &&
                                    isPasswordResetVisible.id ===
                                      data?.contractorID ? (
                                      <ContractorClaimantStaffPasswordReset
                                        htmlFor={"my-modal-3"}
                                        id={data?.contractorID}
                                        onClose={() =>
                                          setIsPasswordResetVisible({
                                            id: null,
                                            isVisible: false,
                                          })
                                        }
                                      />
                                    ) : null}
                                  </>
                                )}

                                <span
                                  className="tooltip text-left"
                                  data-tip="View Service Zipcodes"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-management/contractor-service-location/${encryptId(
                                      data?.contractorID
                                    )}`}
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={zip}
                                    />
                                  </Link>
                                </span>

                                <span
                                  className="tooltip text-left whitespace-normal"
                                  data-tip="View Rate Table"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-management/contractor-rates-table/${encryptId(
                                      data?.contractorID
                                    )}/${
                                      (data?.firstName ?? "N/A") +
                                      " " +
                                      (data?.lastName ?? "")
                                    }/${encryptencryptId(data?.ratectCode)}`}
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={rate_table}
                                    />
                                  </Link>
                                </span>
                              </div>
                              <div className="w-full flex items-center space-x-4">
                                <span
                                  className="tooltip text-left whitespace-normal"
                                  data-tip="View Language List"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-management/contractor-language/${encryptId(
                                      data?.contractorID
                                    )}`}
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={language}
                                    />
                                  </Link>
                                </span>

                                <span
                                  className="tooltip text-left whitespace-normal"
                                  data-tip="View Vehicle List"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-management/contractor-vehicle/${encryptId(
                                      data?.contractorID
                                    )}`}
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={vehicle}
                                    />
                                  </Link>
                                </span>

                                <span
                                  className="tooltip text-left whitespace-normal h-4 w-4"
                                  data-tip="View Driver List"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-mangement/contractor-driver/${encryptId(
                                      data?.contractorID
                                    )}`}
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={driver}
                                    />
                                  </Link>
                                </span>

                                <span
                                  className="tooltip text-left whitespace-normal"
                                  data-tip="View Available Hours"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/contractor-management/contractor-availableHours/${encryptId(
                                      data?.contractorID
                                    )}`}
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={hours}
                                    />
                                  </Link>
                                </span>
                              </div>
                            </span>
                          </button>
                        </div>
                      </td>

                    </tr>
                  ))}

                {!contractorDataLoading &&
                  contractorSearchListData?.length === 0 && (
                    <tr>
                      <td colSpan={11} className="my-10 text-center">
                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                        <p className=" font-semibold text-lg">
                          No Contractor Found
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {contractorSearchListData?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                // onPageChange={handlePageChange}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {contractorDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ContractorManagement;
