import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postMethodData } from "../../../../ApiMethods/Api";
import encryptParamID from "../../../../utils/encryptParamID";
import { extractDate, formatDateDifference, safeParseJSON } from "../../../../utils/helpers";
import L from "leaflet";
import TransportAssignmentHistory from "../ReservationAssignmentHistory/TransportAssignmentHistory";
import TranslationAssignmentHistory from "../ReservationAssignmentHistory/TranslationAssignmentHistory";
import TransInterAssignmentHistory from "../ReservationAssignmentHistory/TransInterAssignmentHistory";
import SignalRComponent from "../../../../Common/Sockets/SignalR";
import AssignmentCancellationReviews from "./AssignmentCancellationReviews";
import AssignmentClaimantReviews from "./AssignmentClaimantReviews";
import AssignmentTrackHistory from "./AssignmentTrackHistory";
import AssignmentCost from "./AssignmentCost";
import ReservationDetails from "./ReservationDetails";
import { useDispatch, useSelector } from "react-redux";
import decryptParamID from "../../../../utils/decryptParamID";
import { setAssignmentTrackingDataRedux } from "../../../../Redux-Toolkit/AssignmentTrackingStatsSlice";
import LoadingPage from "../../../../Common/LoaderPage/LoadingPage";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ReservationAssignmentDetails = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = decryptParamID(useParams());

  const [assignmentDetails, setAssignmentDetails] = useState({});
  const [assignedContractorDetails, setAssignedContractorDetails] = useState([]);
  const [reservationStatus, setReservationStatus] = useState(false);
  const [assignmentCancelled, setAssignmentCancelled] = useState(null);
  const [isFeedbackComplete, setIsFeedbackComplete] = useState(null);
  const [claimantFeedbackData, setClaimantFeedbackData] = useState([]);
  const [assignmentCompleteCost, setAssignmentCompleteCost] = useState(null);
  const [assignmentDueDate, setAssignmentDueDate] = useState(null);
  const [assignmentDueTime, setAssignmentDueTime] = useState(null);
  const [isAssignmentTracking, setIsAssignmentTracking] = useState(null);
  const [assignmentTrackingData, setAssignmentTrackingData] = useState([]);
  const [deadMiles, setDeadMiles] = useState(null);
  const [assignmentType, setAssignmentType] = useState("");
  const [transportType, setTransportType] = useState("");
  const [pickupLatLong, setPickupLatLong] = useState({});
  const [dropoffLatLong, setDropoffLatLong] = useState({});
  const [waitingRecordsList, setWaitingRecordsList] = useState("");
  const [createDate, setCreateDate] = useState("");

  const assignmentTrackingDataWithDeadMiles = useSelector((state) =>
    state?.assignmentTrackingStats?.assignmentTrackingDataWithDeadMiles
  );

  const signalRCurrentButtonId = useSelector((state) => state?.signalR?.assignmentCurrentButtonId);
  const [currentButtonId, setCurrentButtonId] = useState([]);
  const [assignmentDataLoading, setAssignmentDataLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    reservationSearchList("/ReservationAssignments", Number(id), (response) => {
      if (response?.data?.data?.length === 0) {
        setAssignmentDetails({});
        setReservationStatus("");
        setAssignmentDataLoading(false);
      } else {
        setAssignmentDetails(response?.data?.data[0]);
        setAssignmentType(response?.data?.data[0]?.assgncode);
        setDeadMiles(response?.data?.data[0]?.deadMiles);
        setTransportType(response?.data?.data[0]?.rsvattcode);
        setAssignmentDataLoading(true);
        const contractorDetails = {
          contractor: response?.data?.data[0]?.contractor,
          contractorCompany: response?.data?.data[0]?.contractorCompany,
          contractorEmail: response?.data?.data[0]?.contractorEmail,
          contractorFax: response?.data?.data[0]?.contractorFax,
          contractorCellphone: response?.data?.data[0]?.contractorcellphone,
          contractorContact: response?.data?.data[0]?.contractorcontact,
          contractorHomephone: response?.data?.data[0]?.contractorhomephone,
          contractorId: response?.data?.data[0]?.contractorid,
          contractorIntfId: response?.data?.data[0]?.contractorintfid,
          contractorShortName: response?.data?.data[0]?.contractorshortname,
        };

        setPickupLatLong({
          lat: response?.data?.data[0]?.puLatitude,
          lng: response?.data?.data[0]?.puLongitude,
        });
        setDropoffLatLong({
          lat: response?.data?.data[0]?.doLatitude,
          lng: response?.data?.data[0]?.doLongitude,
        });
        setAssignedContractorDetails(contractorDetails);
        setReservationStatus(response?.data?.data[0]?.actionCode);
        setAssignmentCancelled(response?.data?.data[0]?.inactiveflag);
        setIsFeedbackComplete(response?.data?.data[0]?.isFeedbackComplete);
        setIsAssignmentTracking(response?.data?.data[0]?.isAssignmentTracking);
        setAssignmentCompleteCost(response?.data?.data[0]?.assignmentCompleteCost);
        setAssignmentDueDate(response?.data?.data[0]?.reservationDate);
        setAssignmentDueTime(response?.data?.data[0]?.pickupTime);
        setWaitingRecordsList(response?.data?.data[0]?.waitingRecordsList);
        setCreateDate(response?.data?.data[0]?.createDate);
      }
    });
  }, [id]);

  const extracteddate = extractDate(assignmentDueDate);
  const reqRes = formatDateDifference(extracteddate);

  useEffect(() => {
    isFeedbackComplete === 1 &&
      assignmentFeedbackAndTracking(
        "/ViewUserFeedback",
        Number(id),
        (response) => {
          if (response?.data?.data?.length === 0) {
            setClaimantFeedbackData([]);
          } else {
            setClaimantFeedbackData(response?.data?.data[0]);
          }
        }
      );

      console.log("isAssignmentTracking", isAssignmentTracking);
      

    isAssignmentTracking === 1 &&
      assignmentFeedbackAndTracking(
        "/TrackAssignment",
        Number(id),
        (response) => {
          if (response?.data?.data?.length === 0) {
            setAssignmentTrackingData([]);
          } else {
            setAssignmentTrackingData(response?.data?.data);
            dispatch(setAssignmentTrackingDataRedux(response?.data?.data));
          }
        }
      );
  }, [isAssignmentTracking, isFeedbackComplete, id, signalRCurrentButtonId]);

  console.log("setAssignmentTrackingData", assignmentTrackingData);
  

  useEffect(() => {
    const buttonIds = [];
    for (let i = 0; i < assignmentTrackingDataWithDeadMiles?.length; i++) {
      buttonIds.push(assignmentTrackingDataWithDeadMiles[i]?.currentButtonID);
    }
    setCurrentButtonId(buttonIds);
  }, [assignmentTrackingDataWithDeadMiles]);

  const calculateDistance = (latlng1, latlng2) => {
    if (!latlng1 || !latlng2) return null;
    if (!latlng1 || !latlng2) return null;
    else {
      const point1 = L.latLng(latlng1?.latitude, latlng1?.longitude);
      const point2 = L.latLng(latlng2?.latitude, latlng2?.longitude);

      // distance in meters
      const distance = point1?.distanceTo(point2);

      // Convert to miles if needed
      const distanceInMiles = distance * 0.000621371;

      return parseFloat(distanceInMiles).toFixed(2);
    }
  };

  const fetchLocationName = async (locationParam) => {    
    if (locationParam) {
      try {
        const location = locationParam;

        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location?.latitude},${location?.longitude}&key=${GOOGLE_MAPS_API_KEY}`;

        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          if (data.status === "OK" && data.results.length > 0) {
            let formattedAddress = data.results[0].formatted_address;
            // Remove the first part of the address
            formattedAddress = formattedAddress.split(", ").slice(1).join(", ");
            return formattedAddress;
          } else {
            return "Location name not found";
          }
        } else {
          return "Location name not found";
        }
      } catch (error) {
        console.error(
          "An error occurred while trying to get the location name",
          error
        );
      }
    }
  };

  const goBack = () => {
    const element = document.getElementById("activeTableRowID");

    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 125;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    window.history.back();
  };

  const assignmentFeedbackAndTracking = async (url, id, callback) => {
    const body = {
      reservationsAssignmentsID: Number(id),
    };

    try {
      const response = await postMethodData(url, body);
      callback(response);
    } catch (error) {
      console.error("An error occurred while trying to get the results", error);
      setAssignmentDetails({});
    }
  };

  const reservationSearchList = async (url, id, callback) => {
    const body = {
      reservationID: 0,
      reservationsAssignmentsID: id,
      contractorID: 0,
      dateFrom: "",
      dateTo: "",
      claimID: 0,
      claimantID: 0,
      customerID: 0,
      assgnCode: "",
      rsvacCode: "",
      inactiveflag: -2,
      page: 1,
      limit: 20,
    };

    try {
      const response = await postMethodData(url, body);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setAssignmentDetails({});
    }
  };

  /**
   * Feedback Assignment Questions
   */
  const feedbackQuestion = [
    {
      id: 1,
      question: "How was your Experience?",
      options: ["Excellent", "Good", "Average", "Poor"],
    },
    {
      id: 2,
      question: "How was vehicle condition?",
      options: ["Excellent", "Good", "Average", "Poor"],
    },
    {
      id: 3,
      question: "How safe you felt?",
      options: ["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied"],
    },
    {
      id: 4,
      question: "Was driver there on time?",
      options: ["Yes", "No"],
    },
    {
      id: 5,
      question: "Driver talk on the phone without a handsfree device?",
      options: ["Yes", "No", "Sometimes"],
    },
  ];

  /**
   * Method to show the assignment history based on the assignment type
   * @param {object} assignmentTrackingData
   * @param {integer} index
   * @param {string} contractorStartedLocation
   * @param {integer} deadMiles
   * @returns
   */
  const assignmentHistory = (trackingData, index) => {
    
    switch (trackingData?.assgncode) {
      case "TRANSPORT":
        return (
          <TransportAssignmentHistory
            currentbuttonId={currentButtonId}
            fetchLocationName={fetchLocationName}
            calculateDistance={calculateDistance}
            assignmentTrackingData={assignmentTrackingDataWithDeadMiles}
            trackingData={trackingData}
            index={index}
          />
        );
      case "TRANSLATE":
        return (
          <TranslationAssignmentHistory
            currentbuttonId={currentButtonId}
            fetchLocationName={fetchLocationName}
            calculateDistance={calculateDistance}
            assignmentTrackingData={assignmentTrackingDataWithDeadMiles}
            trackingData={trackingData}
            index={index}
          />
        );
      case "INTERPRET":
        return (
          <TranslationAssignmentHistory
            currentbuttonId={currentButtonId}
            index={index}
            fetchLocationName={fetchLocationName}
            assignmentTrackingData={assignmentTrackingDataWithDeadMiles}
            calculateDistance={calculateDistance}
            trackingData={trackingData}
          />
        );
      case "TRANSINTERP":
        return (
          <TransInterAssignmentHistory
            currentbuttonId={currentButtonId}
            fetchLocationName={fetchLocationName}
            calculateDistance={calculateDistance}
            assignmentTrackingData={assignmentTrackingData}
            trackingData={trackingData}
            rsvattcode={transportType}
            index={index}
          />
        );
      default:
        return <div>No assignment code matched</div>;
    }
  };

  /**
   * Method to check if the map is allowed to be shown for the assignment type
   * @param {interger} index
   * @returns {boolean} true/false
   */
  const checkAllowedMap = (index) => {
    switch (assignmentType) {
      case "TRANSINTERP":
        return index !== 2;
      case "TRANSLATE":
        return index === 0;
      case "INTERPRET":
        return index === 0;
      case "TRANSPORT":
        return true;
      default:
        return false;
    }
  };

  const claimantPickedupLatLng =
    assignmentTrackingData?.claimantPickedupLatitudeLongitude
      ? safeParseJSON(assignmentTrackingData.claimantPickedupLatitudeLongitude)
      : null;

  return (
    <>
    {/* -------------------- SignalR Component ------------------- */}
      {assignmentTrackingData[0]?.reservationsAssignmentsID && (
        <SignalRComponent
          reservationsAssignmentsID={
            assignmentTrackingData[0]?.reservationsAssignmentsID
          }
        />
      )}
      <div className=" p-6 flex justify-between">
        <div className=" mb-2">
          <p className=" text-2xl font-semibold">
            Reservation Assignment Details #{assignmentDetails?.assgnNum}
          </p>

          {assignedContractorDetails?.contractor && (
            <p className="mt-2 text-m font-semibold">
              Assignment assigned to{" "}
              <button
                className="mt-2 text-m font-semibold text-[#7ac46b] cursor-pointer capitalize"
                onClick={() => {
                  navigate(
                    `/contractor-management/contractor-details/${encryptParamID(
                      assignedContractorDetails?.contractorId
                    )}`
                  );
                }}
              >
                {assignedContractorDetails?.contractor}
              </button>
            </p>
          )}
        </div>

        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>

      {/* ---------------------------- Reservation Details -------------------------- */}

      <ReservationDetails
        assignmentDetails={assignmentDetails}
        assignmentDueDate={reqRes}
      />

      {/*----------------------------- Cancellation Review ---------------------- */}
      <AssignmentCancellationReviews
        assignmentCancelled={assignmentCancelled}
        assignmentDetails={assignmentDetails}
      />

      {/* ---------------------------- Claimant Review -------------------------- */}
      <AssignmentClaimantReviews
        isFeedbackComplete={isFeedbackComplete}
        claimantFeedbackData={claimantFeedbackData}
        feedbackQuestion={feedbackQuestion}
      />

      {/* --------------------------------- Assignment History ----------------------------- */}
      <AssignmentTrackHistory
        isAssignmentTracking={isAssignmentTracking}
        assignmentTrackingData={assignmentTrackingData}
        assignmentHistory={assignmentHistory}
        checkAllowedMap={checkAllowedMap}
        deadMiles={deadMiles}
        transportType={transportType}
        pickupLatLong={pickupLatLong}
        dropoffLatLong={dropoffLatLong}
        claimantPickedupLatLng={claimantPickedupLatLng}
        assignmentDetails={assignmentDetails}
        assignmentDueDate={reqRes}
        assignmentDueTime={assignmentDueTime}
        assignmentCancelled={assignmentCancelled}
        waitingRecordsList={waitingRecordsList}
        createDate={createDate} 
      />

      {/* ---------------------------- Assignment Cost ------------------------------ */}
      <AssignmentCost
        assignmentCompleteCost={assignmentCompleteCost}
        reservationStatus={reservationStatus}
        assignmentDetails={assignmentDetails}
      />

      {!assignmentDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
    </>
  );
};

export default ReservationAssignmentDetails;
