import React, { useState, useEffect } from "react";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewContractorDetails = () => {

  const { id } = decryptParamID(useParams());
  const contractorFilters = useSelector((state) => state?.contractor?.contractorFilters);
  const [contractorDetails, setContractorDetails] = useState([]);
  const [contractorDetailsDataLoading, setContractorDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    contractorSearchList("/ContractorSearch", Number(id), (response) => {
      if (response?.data?.status === true) {
        setContractorDetails(response?.data?.data[0]);
        setContractorDetailsDataLoading(true);
      } else {
        setContractorDetailsDataLoading(false);
        setContractorDetails([]);
      }
    });
  }, []);

  const contractorSearchList = async (url, id, callback) => {
    const body = {
      firstName: contractorFilters?.contractorName || "",
      lastName: contractorFilters?.contractorName || "",
      mobile: contractorFilters?.mobile || "",
      contractorID: id || "",
      contractorName: contractorFilters?.contractorName || "",
      email: contractorFilters?.email || "",
      state: contractorFilters?.state || "",
      statusCode: contractorFilters?.statusCode || "",
      serviceCode: contractorFilters?.serviceCode || "",
      inactiveflag: contractorFilters?.inactiveflag || "-2",
      page: 1,
      limit: contractorFilters?.limit || 20,
    };
    try {
      const response = await postMethodData(url, body);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setContractorDetails([]);
    }
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Details for{" "}
            <span className="uppercase">
              {contractorDetails?.firstName || contractorDetails?.lastName
                ? `${contractorDetails?.firstName ?? ""} ${contractorDetails?.lastName ?? ""}`.trim()
                : contractorDetails?.company || "N/A"}
            </span>
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
        data-testid="contractorDetails-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="contractorid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Contractor ID
              </label>
              <Input
                name="contractorid"
                value={contractorDetails?.contractorID || "N/A"}
                id="contractorid"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="company"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Company
              </label>
              <Input
                name="company"
                value={contractorDetails?.company || "N/A"}
                id="company"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Email
              </label>
              <Input
                name="email"
                value={contractorDetails?.email || "N/A"}
                id="email"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name
              </label>
              <Input
                name="firstName"
                value={contractorDetails?.firstName || "N/A"}
                id="firstName"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Name
              </label>
              <Input
                name="lastName"
                value={contractorDetails?.lastName || "N/A"}
                id="lastName"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="mailingName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Mailing Name
              </label>
              <Input
                name="mailingName"
                value={contractorDetails?.mailingName || "N/A"}
                id="mailingName"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="gender"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Gender
              </label>
              <Input
                name="gender"
                value={
                  {
                    M: "Male",
                    F: "Female",
                  }[contractorDetails?.gender] || "N/A"
                }
                id="gender"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Phone
              </label>
              <Input
                name="phone"
                value={
                  contractorDetails?.cellPhone ||
                  contractorDetails?.homePhone ||
                  contractorDetails?.workPhone ||
                  contractorDetails?.workPhoneExt ||
                  "N/A"
                }
                id="phone"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <Input
                name="status"
                value={contractorDetails?.statusName || "N/A"}
                id="status"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="service"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Service
              </label>
              <Input
                name="service"
                value={contractorDetails?.service || "N/A"}
                id="service"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Address
              </label>
              <textarea
                name="address"
                value={
                  contractorDetails?.address1 ||
                  contractorDetails?.address2 ||
                  contractorDetails?.address3 ||
                  contractorDetails?.mlAddress1 ||
                  contractorDetails?.mlAddress2 ||
                  contractorDetails?.mlAddress3 ||
                  "N/A"
                }
                id="address"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="city"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <Input
                name="city"
                value={contractorDetails?.city || "N/A"}
                id="city"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code
              </label>
              <Input
                name="zipcode"
                value={contractorDetails?.zipCode || "N/A"}
                id="zipcode"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between"></div>
        </form>
        {!contractorDetailsDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
            data-testid="loading"
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewContractorDetails;
