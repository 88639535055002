import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ContractorLanguageList = () => {

  const { id } = decryptParamID(useParams());

  const [companyName, setCompanyName] = useState([]);
  const contractorID = id || "";
  const [contractorDataLoading, setContractorDataLoading] = useState(true);
  const [contractors, setContractors] = useState([]);
  const ITEMS_PER_PAGE = 50;
  const currentPage = 0;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    getData("/ContractorLanguage", id, (response) => {

      if (!response?.data?.status) {
        setContractors([]);
      } else if (response?.data?.status) {
        setContractors(response?.data?.data);
        return response;
      }
    });

    setContractorDataLoading(false);
    getData("/ContractorSelDetail", id, (response) => {
      if (response?.data?.status === true) {
        setCompanyName(response?.data?.data[0]?.company);
      } else {
        setCompanyName([]);
      }
    });

  }, []);

  const getData = async (url, id, callback) => {
    try {
      const data = {
        contractorID: id,
      };
      const response = await postMethodData(url, data);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer claimant list:",
        error
      );
    }
  };

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Language List for: {contractorID} | {companyName}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th style={{ whiteSpace: "normal" }}>Language</th>
                <th style={{ whiteSpace: "normal" }}>Translate</th>
                <th style={{ whiteSpace: "normal" }}>Interpret</th>
                <th style={{ whiteSpace: "normal" }}>Transcribe</th>
                <th style={{ whiteSpace: "normal" }}>Certified</th>
                <th style={{ whiteSpace: "normal" }}>Certification Type</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {contractorDataLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {contractors
                .map((data, index) => (
                  <tr key={data?.langName + data?.conlctCode}>
                    <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>
                    <td>{data?.langName || "N/A"}</td>
                    <td className=" capitalize">
                      {(data?.translateflag === -1 ? "YES" : "NO") || "N/A"}
                    </td>

                    <td className=" capitalize">
                      {(data?.interpretflag === -1 ? "YES" : "NO") || "N/A"}
                    </td>
                    <td className=" capitalize">
                      {(data?.transcribeflag === -1 ? "YES" : "NO") || "N/A"}
                    </td>

                    <td>
                      {(data?.certifiedFlag === -1 ? "YES" : "NO") || "N/A"}
                    </td>
                    <td>{data?.conlctCode || "N/A"}</td>
                  </tr>
                ))}

              {!contractorDataLoading && contractors?.length === 0 && (
                <tr>
                  <td colSpan={11} className="my-10 text-center">
                    <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Language Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {contractorDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
    </>
  );
};

export default ContractorLanguageList;
